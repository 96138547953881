<template>
    <div>
        <div class="col-md-12">
            <div class="row" style="margin-top: 20px">
                <div class="col-md-12">
                    <h3 class="page-header">
                        <i class='bx bx-store'></i>
                        Stock Transfer
                    </h3>
                </div>
                <!-- <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6"></div>
                        <div class="col-md-6" style="padding-top: 18px">
                            <button @click="refresh()" class="btn btn-primary">
                                <i class="fa fa-refresh"></i> Refresh
                            </button>
                            <button v-if="edititem != null" @click="back()" class="btn btn-primary">
                                Back
                            </button>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="panel panel-cascade">
            <div class="panel-body">
                <div class="form-horizontal cascde-forms">
                    <div class="col-md-12">
                        <div class="form-horizontal cascde-forms">
                            <div class="form-group row">
                                <div class="col-lg-4 col-md-3">
                                    <label for="subcategory" class="col-form-label control-label">Party : From</label>
                                </div>
                                <div class="col-md-6">
                                    <select class="form-control form-cascade-control input-small" v-model="partyfrom">
                                        <option v-for="item in stores" v-bind:value="item.id" :key="item.id">
                                            {{ item.name }}</option>
                                    </select>
                                    <span class="invalid-feedback" role="alert">
                                        <strong></strong>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-lg-4 col-md-3">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label for="subcategory" class="col-form-label control-label">Party :
                                                To</label>
                                        </div>
                                        <!-- <div class="col-md-4 flex-center-row">
                                            <input type="checkbox" id="employeedata" @change="fetchEmployee($event)"/>
                                            <label for="employeedata" class="ml-5">Employee</label>
                                        </div>
                                        <div class="col-md-4 flex-center-row">
                                            <input type="checkbox"  @change="fetchStores($event)" id="storedata"/>
                                            <label for="storedata" class="ml-5">Store</label>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <select @change="checkParty()" class="form-control form-cascade-control input-small"
                                        v-model="partyto">
                                        <option v-for="item in employees" v-bind:value="item.id" :key="item.id">
                                            {{ item.name }}</option>
                                    </select>
                                    <span class="invalid-feedback" role="alert">
                                        <strong></strong>
                                    </span>
                                </div>
                            </div>
                            <!-- <div class="form-group row">
                                <div class="col-lg-4 col-md-3">
                                    <label for="gender" class="col-form-label control-label">Purchase No.<span
                                            style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" disabled v-model="serialno"
                                        class="form-control form-cascade-control input-small">
                                    <span class="invalid-feedback" role="alert">
                                        <strong></strong>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-lg-4 col-md-3">
                                    <label for="gender" class="col-form-label control-label">Invoice No.<span
                                            style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" v-model="form.invoiceid"
                                        class="form-control form-cascade-control input-small">
                                    <span class="invalid-feedback" role="alert">
                                        <strong></strong>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-lg-4 col-md-3">
                                    <label for="date" class="col-form-label control-label">Invoice Date<span
                                            style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                </div>
                                <div class="col-md-6">
                                    <input type="date" v-model="form.invoicedate"
                                        class="form-control form-cascade-control input-small">
                                    <span class="invalid-feedback" role="alert">
                                        <strong></strong>
                                    </span>
                                </div>
                            </div> -->
                            <div class="form-group row">
                                <div class="col-lg-4 col-md-3">
                                    <label for="subcategory" class="col-form-label control-label">Select
                                        Category</label>
                                </div>
                                <div class="col-md-6">
                                    <select class="form-control form-cascade-control input-small"
                                        v-model="form.parentid">
                                        <option selected>----Select-----</option>
                                        <option v-for="item in parentcategory" v-bind:value="item.id" :key="item.id">
                                            {{ item.name }}</option>
                                    </select>
                                    <span class="invalid-feedback" role="alert">
                                        <strong></strong>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-lg-4 col-md-3">
                                    <label for="subcategory" class="col-form-label control-label">Select Product</label>
                                </div>
                                <div class="col-md-6">
                                    <select @change="fetchbrands()"
                                        class="form-control form-cascade-control input-small" v-model="category_id">
                                        <option selected>----Select-----</option>
                                        <option v-for="item in subcategory" v-bind:value="item.id" :key="item.id">
                                            {{ item.name }}</option>
                                    </select>
                                    <span class="invalid-feedback" role="alert">
                                        <strong></strong>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class=" col-lg-4 col-md-3">
                                    <label for="subcategory" class="col-form-label control-label">Company</label>
                                </div>
                                <div class="col-md-6">
                                    <select v-model="brandid" @change="fetchproducts()"
                                        class="form-control form-cascade-control input-small">
                                        <option v-for="filter in brands" :key="filter.id" :value="filter.id">
                                            {{ filter.value }}</option>

                                    </select>
                                    <span class="invalid-feedback" role="alert">
                                        <strong></strong>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-lg-4 col-md-3">
                                    <label for="firstname" class="col-form-label control-label">Model Name<span
                                            style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                </div>
                                <div class="col-md-6">
                                    <select v-model="form.productid" @change="getAllBarcode()"
                                        class="form-control form-cascade-control input-small">
                                        <option v-for="filter in products" :key="filter.id" :value="filter.id">
                                            {{ filter.name }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-lg-4 col-md-3">
                                    <label for="firstname" class="col-form-label control-label">Remain
                                        Quantity<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" class="form-control form-cascade-control input-small"
                                        v-model="remainquantity" disabled />
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-lg-4 col-md-3">
                                    <label for="firstname" class="col-form-label control-label">Quantity<span
                                            style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" class="form-control form-cascade-control input-small"
                                        v-model="quantity" @input="checkQuantity()" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-4">
                                    <label for="gender" class="col-form-label control-label">Serial No./
                                        IMEI<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                </div>
                                <div class="col-md-6">
                                    <select v-model="barcodeid" @change="fillBarcode()"
                                        class="form-control form-cascade-control input-small">
                                        <option v-for="filter in allbarcode" :key="filter.id" :value="filter.id">
                                            <span>{{ filter.barcode }}</span> <span v-if="filter.colour"> /
                                                {{
                                                    filter.colour }}</span>
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <!-- <div class="form-group row">
                                <div class="col-lg-4 col-md-3">
                                    <label for="firstname" class="col-form-label control-label">Quantity<span
                                            style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" class="form-control form-cascade-control input-small"
                                        v-model="quantity" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-4">
                                    <label for="gender" class="col-form-label control-label">Serial No./
                                        IMEI<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                </div>
                                <div class="col-md-6">
                                    <select v-model="barcodeid" @change="fillBarcode()"
                                        class="form-control form-cascade-control input-small">
                                        <option v-for="filter in allbarcode" :key="filter.id" :value="filter.id">
                                            <span>{{ filter.barcode }}</span> <span v-if="filter.colour"> / {{
                                                filter.colour }}</span>
                                        </option>
                                    </select>
                                </div>
                            </div> -->
                            <div class="form-group row">
                                <div class="col-md-4">

                                </div>
                                <div class="col-md-6">
                                    <div v-if="this.barcodes.length > 0" class="row">
                                        <div class="col-md-6" v-for="item in barcodes" :key="item.id">
                                            <div class="form-control form-cascade-control input-small pr"
                                                style="margin-top: 5px;"> <span>{{ item.barcode }}</span> <span
                                                    v-if="item.colour">/ {{ item.colour }}</span>
                                            </div>
                                            <span class="trash_bin" @click="deleteBarcode(item)"><i
                                                    class='bx bxs-trash font-17'></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="form-group row">
                                <div class="col-lg-4 col-md-3">
                                    <label for="gender" class="col-form-label control-label">Quantity<span
                                            style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" v-model="quantity" @input="getAllBarcode()"
                                        class="form-control form-cascade-control input-small">
                                    <span class="invalid-feedback" role="alert">
                                        <strong></strong>
                                    </span>
                                </div>
                                <div class="col-md-2 flex-start-row">

                                </div>
                            </div> -->


                            <!-- <div class="form-group row" v-if="this.barcodes.length > 0">
                                <div class="col-md-4">
                                    <div class="row">

                                        <div class="col-md-8">
                                            <label for="gender" class="col-form-label control-label">Serial No./ IMEI<span
                                                    style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                                        </div>
                                        <div class="col-md-4 flex-start-row">
                                            <input type="checkbox" id="randomly" @change="randomlyBarcode2()"
                                                :disabled="this.loading ? true : false" v-model="isChecked">
                                            <label for="randomly" class="ml-5">Randomly</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-8 row">
                                    <div class="col-md-6  " v-for="(value, index) in barcodes" :key="index">
                                        <barcode-item :value="value" :index="index"
                                            @updateedititem="barcodeupdated($event, index)"></barcode-item>
                                    </div>

                                </div>


                            </div> -->
                            <div class="form-">
                                <button type="button" class="btn bg-primary text-white btn-md"
                                    :disabled="this.quantity > 0 && this.quantity == this.chooseBarcode ? false : true"
                                    @click="addproduct()">
                                    <span v-if="form.productid != 0">Add In Cart</span>
                                    <span v-if="form.productid == 0">Select Product</span>
                                </button>
                                <span v-show="showmsg == true" style="color:red"> Select Product First</span>
                            </div>

                            <hr>
                            <cart></cart>
                            <div class="form-group row">
                                <div class="col-md-3">

                                </div>
                                <div class="col-md-6">
                                    <input style="width: 50%;margin-top: 10%;" type="button" value="Submit"
                                        @click="submit()" class="btn bg-primary text-white btn-sm chkcondition">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Constants from '../../components/utilities/Constants.vue'
import Cart from '../cart/Cart.vue'
// import BarcodeItem from '../cart/BarcodeItem.vueu
// import axios from 'axios'
export default {
    mixins: [Constants],
    components: { Cart, },
    data() {
        return {
            form: new window.Form({
                id: 0, customerid: 0, invoiceid: 0, purchaseno: 0, invoicedate: null, parentid: 0, parent_id: 0,
                brandid: 0, productid: 0, customername: '', mobileno: null, quantity: 0, price: 0, manufdate: null,
                netpricewithouttax: 0
            }),
            products: [],
            category_id: 0,
            brandid: 0,
            brands: [],
            serialno: 0,
            showmsg: false,
            barcodes: [],
            param: { id: 0, quantity: 1, barcode: '', colour: '' },
            quantity: 0,
            isChecked: false,
            loading: false,
            allbarcode: [],
            partyfrom: '',
            partyto: '',
            barcodeid: 0,
            chooseBarcode: 0,
            allstore: [],
            remainquantity: 0
        }
    },

    computed: {
        ...mapGetters([
            'cartitems', 'viewno', 'parties', 'loggedinuser', 'categories', 'attributes', 'allproducts',
            'hsnbasedtaxes', 'edititem', 'totalnetprice', 'employees', 'stores', 'selectedstoreid'
        ]),
        parentcategory() {
            if (this.categories != null) {
                return this.categories.filter(block => block.parent_id == 0)
            }
            return [];
        },
        subcategory() {
            if (this.categories != null) {
                return this.categories.filter(block => block.parent_id == this.form.parentid)
            }
            return [];
        },

        predefinedvalues() {
            if (this.brands != null && this.brands.predefinedvalues != null) {
                return this.brands.predefinedvalues
            }
            return [];
        },

        filters() {
            return this.categories.filter(item => item.parent_id != 0 && item.parent_id != null)
        },
        selectedproduct() {
            if (this.products != null) {
                return this.products.find(block => block.id == this.form.productid)
            }
            return [];
        },
        finalamount() {
            let amt = parseInt(this.form.quantity) * parseInt(this.form.price)
            return amt
        },

    },
    mounted() {
        this.initializedata();
        this.refresh();

    },


    methods: {
        refresh() {
            this.$store.dispatch('fetchemployees')

            this.$store.dispatch("fetchproducts")
            this.$store.dispatch('fetchcategories')
            this.$store.dispatch('fetchattributes')
            this.$store.dispatch('fetchmaster')
            this.$store.dispatch('fetchparties')
            // window.axios.post('api/usersequence/fetchpurchase')
            //     .then(response => this.processSequenceResponse(response.data))
            //     .catch(error => console.log(error));
        },
        // processSequenceResponse(data) {
        //     if (this.edititem == null) {
        //         this.serialno = parseInt(data) + 1
        //     }
        // },
        addproduct() {

            if (this.form.parentid != 0) {
                if (this.quantity > 0) {
                    this.productchange();
                } else {
                    this.$notify({
                        text: 'Quantity cannot be 0.',
                        type: 'warn',
                        speed: '500',
                    })
                }
            } else {
                this.showmsg = true
            }


        },
        initializedata() {
            if (this.edititem != null) {
                console.log("edititem", this.edititem)
                this.form.id = this.edititem.id
                // this.serialno = this.edititem.usersequence
                this.form.customerid = this.edititem.customerid
                this.form.invoiceid = this.edititem.invoiceid
                this.form.purchaseno = this.edititem.purchaseno
                this.form.invoicedate = this.edititem.invoicedate
                this.form.customername = this.edititem.customername
                this.form.trantype = this.edititem.trantype
                this.form.isinvoice = this.edititem.isinvoice

            } else {
                this.form = new window.Form({
                    id: 0, customerid: 0, invoiceid: 0, purchaseno: 0, invoicedate: null, parentid: 0, parent_id: 0,
                    brandid: 0, productid: 0, customername: '', mobileno: null, quantity: 0, price: 0, manufdate: null,
                    netpricewithouttax: 0
                })
                this.products = [],
                    this.category_id = 0,
                    this.brandid = 0,
                    this.brands = [],
                    this.barcodes = []
                this.quantity = 0
                this.partyfrom = '',
                    this.partyto = '',
                    this.barcodeid = 0,
                    this.chooseBarcode = 0,
                    this.$store.commit('assigntransaction', null)
                this.$store.commit('assignedititem', null)
                this.$store.commit('assigncartitems', [])
                this.allbarcode = []
            }
        },
        setapplicabletaxes() {
            let alltaxes = this.getmastertype(this.MASTER_TAXES)
            let outsideup = false;

            if (outsideup) {
                let index = alltaxes.findIndex(block => block.labelid == this.CGST_LABELID)
                if (index > -1)
                    alltaxes.splice(index, 1)
                index = alltaxes.findIndex(block => block.labelid == this.SGST_LABELID)
                if (index > -1)
                    alltaxes.splice(index, 1)
            } else {

                let index = alltaxes.findIndex(block => block.labelid == this.IGST_LABELID)

                if (index > -1)
                    alltaxes.splice(index, 1)
            }

            this.$store.commit("assignapplicabletaxes", alltaxes)
        },

        fetchbrands() {

            let param = { parentcategoryid: this.category_id, attributeid: 2 }
            window.axios.post('api/attribute/values/fetchbycategory', param)
                .then(response => this.processAvailableBrands(response.data))
                .catch(error => console.log(error));
        },
        processAvailableBrands(data) {
            this.brands = data
        },
        fetchproducts() {
            let param = { parentcategoryid: this.category_id, attributeid: 2, attributevalueids: this.brandid }
            window.axios.post('api/products/fetchbyfilters', param)
                .then(response => this.processProductsResponse(response.data))
                .catch(error => console.log(error));
        },
        processProductsResponse(data) {
            let createSet = new Set();
            let uniqueArray = data.filter(obj => {
                let mappingObj = obj.name;
                if (!createSet.has(mappingObj)) {
                    createSet.add(mappingObj);
                    return true;
                }
                return false;
            });
            this.products = uniqueArray

        },
        productchange() {
            this.setapplicabletaxes()
            this.$store.commit("assignselectedproductid", this.form.productid)
            let newitem = this.products.find(block => block.id == this.form.productid)
            let newitem1 = { ...newitem }
            newitem1.sp = newitem.cp
            newitem1.quantity = this.quantity
            newitem1.barcodes = this.barcodes
            this.$store.commit('addcartitem', newitem1);
            this.$store.commit('updatetrantype', 1);
            this.parentid = 0
            this.category_id = 0
            this.brandid = 0
            this.form.productid = 0
            this.quantity = 0
            this.barcodes = []
            this.loading = false
            this.isChecked = false
            this.allbarcode = []
            this.remainquantity = 0

        },

        submit() {

            if (this.cartitems.length > 0) {
                let param = {
                    items: this.cartitems,
                    amountwithtax: this.totalnetprice, amtafterdiscount: this.totalnetpriceafterdiscount,
                    customerid: this.form.customerid, purchaseno: this.form.purchaseno,
                    invoicedate: this.form.invoicedate == null ? new Date().toISOString().slice(0, 10) : this.form.invoicedate,
                    transactionid: this.form.id, trantype: 5, invoiceid: this.form.invoiceid,
                    address_id: 0, transactiondate: new Date().toISOString().slice(0, 10),
                    isinvoice: 1, hsnbasedtaxes: null, mfgdate: this.form.mfgdate,
                    parentid: this.form.parentid, brandid: this.brandid, oldtrantype: 0,
                    customername: this.customername, mobileno: this.mobileno,
                    // usersequence: this.serialno,
                    userid: this.partyfrom, userid2: this.partyto
                }
                console.log(param)
                // this.$store.commit('assignloadingstatus', 1)
                // window.axios.post('api/submittransaction2', param)
                //     .then(response => this.processResponse(response.data))
                //     .catch(error => console.log(error));
                // this.$notify({
                //     text: 'Submitting...',
                //     type: 'warn',
                //     duration: '50000',
                //     speed: '500',
                //     closeOnClick: false
                // })
            }
            else {
                this.$notify({
                    text: 'Fill all required field',
                    type: 'success',
                    duration: '50000',
                    speed: '500',
                    closeOnClick: false
                })
            }
        },
        processResponse() {
            this.$notify({
                text: 'Submitted',
                type: 'success',
                duration: '50000',
                speed: '500',
                closeOnClick: false
            })
            this.$store.commit('assignloadingstatus', 0)
            this.$store.commit('assignedititem', null)
            this.initializedata();
            this.refresh();
            this.$store.commit('clear');
            this.$store.commit('assignviewno', 1);

        },
        back() {
            this.$store.commit("assignviewno", 1)
            this.$store.commit("assignedititem", null)
        },
        getAllBarcode() {
            this.fetchProductQuantity()
            let param = { productid: this.form.productid,storeid: this.selectedstoreid }
            window.axios.post('api/fetch/all/barcodes', param)
                .then((response) => this.processBracodesResponse(response.data))
                .catch((error) => console.log(error))
        },
        processBracodesResponse(data) {
            this.allbarcode = data.barcodes
        },
        // getBarcode(quantity, param) {
        //     this.barcodes = []
        //     if (quantity > 0) {
        //         for (let i = 0; i < quantity; i++) {
        //             this.barcodes.push(param)
        //         }
        //     } else {
        //         this.barcodes = []
        //     }
        // },
        // barcodeupdated(event, index) {
        //     console.log("Printing the event value")
        //     console.log(event)
        //     this.barcodes[index] = {
        //         id: 0,
        //         barcode: event?.barcode,
        //         colour: event?.colour,
        //         quantity: 1
        //     }
        // },
        // savebarcode() {
        //     console.log("bar code", this.barcodes)
        //     this.isChecked = false
        //     this.loading = true
        // },
        // randomlyBarcode2() {
        //     if (this.isChecked) {
        //         this.$store.commit('assignloadingstatus', 1)
        //         const date = new Date()
        //         let param = {
        //             productid: this.form.productid, quantity: this.quantity,
        //             month: date.getMonth() + 1, year: date.getFullYear(),
        //         }
        //         axios.post('api/barcode/lastsequence', param)
        //             .then((response) => this.processRandomBarcodeResponse2(response.data))
        //             .catch((error) => console.log(error))
        //     } else {
        //         console.log("checked value", this.isChecked)
        //         for (let i = 0; i < this.quantity; i++) {
        //             this.$set(this.barcodes, i, { ...this.barcodes[i], barcode: '' });
        //         }
        //     }
        // },

        // processRandomBarcodeResponse2(data) {
        //     let startsequence = data?.sequence?.endsequence
        //     if (startsequence == '' || startsequence == null) {
        //         startsequence = 1
        //     }
        //     let param = {
        //         productid: this.form.productid, quantity: this.quantity, sequencelength: 3,
        //         startsequence: startsequence, endsequence: parseInt(startsequence) + parseInt(this.quantity)
        //     }
        //     axios.post('api/barcode/generate', param)
        //         .then((response) => this.processRandomBarcodes(response.data))
        //         .catch((error) => console.log(error))
        // },
        // processRandomBarcodes(data) {
        //     data.forEach(element => {
        //         for (let i = 0; i < element.barcodes.length; i++) {
        //             this.$set(this.barcodes, i, { ...this.barcodes[i], barcode: element.barcodes[i].barcode });
        //         }
        //     });
        //     this.$store.commit('assignloadingstatus', 0)

        // },
        checkParty() {
            console.log("party")
            console.log(this.partyfrom)
            console.log(this.partyto)
            if (this.partyfrom == this.partyto) {
                this.partyto = ''
                this.$notify({
                    text: 'Party cannot be same.',
                    type: 'warn',
                    duration: '50000',
                    speed: '500',
                })
            }
        },
        fillBarcode() {
            if (this.quantity > this.chooseBarcode) {
                let barcode = this.allbarcode.find(block => block.id == this.barcodeid)
                if (this.barcodes.length > 0) {
                    if (barcode && !this.barcodes.includes(barcode)) {
                        this.barcodes.push(barcode);
                        this.chooseBarcode++
                        this.barcodeid = 0;
                    } else {
                        this.barcodeid = 0
                        this.$notify({
                            text: 'Duplicate entry not allowed.',
                            type: 'warn',
                            duration: '-1',
                            speed: '100',
                            closeOnClick: false
                        })
                    }
                } else {
                    this.barcodes.push(barcode)
                    this.chooseBarcode++
                    this.barcodeid = 0;
                }
            } else {
                this.barcodeid = 0
            }
        },
        deleteBarcode(item) {
            for (let i = 0; i < this.barcodes.length; i++) {
                if (this.barcodes[i].id == item.id) {
                    this.barcodes.splice(i, 1)
                }
            }
            this.chooseBarcode--
        },
        fetchEmployee(event) {
            this.allstore = []
            let checked = event.target.checked
            if (checked) {
                this.allstore = this.employees
                // console.log(this.allstore)
            } else {
                this.allstore = []
            }
        },
        fetchStores(event) {
            this.allstore = []
            let checked = event.target.checked
            if (checked) {
                this.allstore = this.stores
                // console.log(this.allstore)
            } else {
                this.allstore = []
            }
        },
        fetchProductQuantity() {
            let param = { productid: this.form.productid, storeid: this.selectedstoreid }
            window.axios.post('api/product/stock/quantity', param)
                .then((response) => this.processProductQuantity(response.data))
                .catch((error) => console.log(error))
        },
        processProductQuantity(data) {
            this.remainquantity = 0
            let remain = data?.stock
            if (remain != null) {
                this.remainquantity = parseFloat(remain.finalquantity).toFixed(2)
            }
        },
        checkQuantity() {
            this.chooseBarcode = 0
            this.barcodes = []
            if (parseFloat(this.remainquantity) < parseFloat(this.quantity)) {
                this.$notify({ text: 'Quantity limit exceed', type: 'error' })
                this.quantity = 0
            }
        },
        // fetchStoreId(event){
        //     let newStore = event.target.value
        //     this.$store.commit('assignselectedstoreid',newStore)
        // }


    },
}
</script>